import { useContext, useEffect, useState } from 'react';
import { GlobalContext, GlobalContextProps } from '../global-context';
import General from '../utils/GeneralUtils';
import { Link, useLocation } from 'react-router-dom';
import { TeamDao } from '../utils/TeamDao';
import { Flex, Table, TableCell, TableRow, ToggleButton } from '@aws-amplify/ui-react';
import UniversalTag from '../custom-components/UniversalTag';
import LoadingBars from '../custom-components/LoadingBars';

export function IDashPage(props: any) {

  const globalContext = useContext(GlobalContext);
  General.setTitle(document, 'Customers', globalContext);

  const location = useLocation();

  const [response, setResponse] = useState<any>();
  const [showUsers, setShowUsers] = useState(false)
  const [showServiceLimits, setShowServiceLimits] = useState(false)
  const [showUsage, setShowUsage] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  
  useEffect(() => {
    if (globalContext?.authInfo?.teamId) {
      getIDash()
    }
  }, [globalContext.authInfo?.teamId, location.search])

  async function getIDash() {
    const idash = await TeamDao.getIDash()
    idash.data.signedUpUsers.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    idash.data.customers.sort((a: any, b: any) => new Date(b.team.createdAt).getTime() - new Date(a.team.createdAt).getTime())
    setResponse(idash?.data)
    setIsLoading(false)
  }

  return <GlobalContext.Consumer>
    {
      (globalContextProps: GlobalContextProps) => <>
        <div className="content content-1000">
          {
            isLoading
              ? <LoadingBars numberOfRows={20} />
              : <>
                  <Flex justifyContent="space-between" alignItems="center">
                    <h2>Customers ({ response?.customers?.length })</h2>
                    <div>
                      <ToggleButton
                        size="small"
                        isPressed={showUsers}
                        onChange={() => setShowUsers(!showUsers)}
                      >
                        { showUsers ? 'Hide users' : 'Show users' }
                      </ToggleButton>
                      <ToggleButton
                        size="small"
                        isPressed={showServiceLimits}
                        onChange={() => setShowServiceLimits(!showServiceLimits)}
                        style={{ marginLeft: 10 }}
                      >
                        { showServiceLimits ? 'Hide service limits' : 'Show service limits' }
                      </ToggleButton>
                    </div>
                  </Flex>
                  <Table size="small" backgroundColor="white">
                    {
                      response?.customers?.map((customer: any, i: number) => {
                        return <>
                          <TableRow key={customer.teamId} backgroundColor='var(--amplify-colors-neutral-2)'>
                            <TableCell>
                              <div style={{ fontSize: 18, marginBottom: 10 }}>#{i + 1} <span style={{ fontWeight: 'bold', marginLeft: 15 }}>{customer.team.name}</span></div>
                              <div>{customer.teamId}</div>
                            </TableCell>
                            <TableCell>
                              {
                                customer.team.tier
                              }
                              {
                                customer.team.inTierSince
                                  ? <div style={{ fontSize: 11, marginTop: 5 }}>Oneoff payment on {customer.team.inTierSince.substring(0, 10)}</div>
                                  : <></>
                              }
                            </TableCell>
                            <TableCell>
                              {
                                customer.team.skAccess
                                  ? <Link target="_blank" to={`https://dashboard.stripe.com/subscriptions/${customer.team.skAccess}`}>Stripe Subscription</Link>
                                  : <></>
                              }
                            </TableCell>
                            <TableCell>
                              {
                                customer.team?.stripeCustomerId?.startsWith('cus_')
                                  ? <Link target="_blank" to={`https://dashboard.stripe.com/customers/${customer.team.stripeCustomerId}`}>Stripe Customer</Link>
                                  : <></>
                              }
                              {
                                customer.team?.stripeCustomerId?.startsWith('gcus_')
                                  ? <Link target="_blank" to={`https://dashboard.stripe.com/guests/${customer.team.stripeCustomerId}`}>Stripe Customer</Link>
                                  : <></>
                              }
                            </TableCell>
                          </TableRow>
                          {
                            showUsers &&
                            <TableRow key={customer.teamId}>
                              <TableCell colSpan={5}>
                                <ul>
                                  {
                                      customer.users.map((user: any) => {
                                        return <li key={user.id}>
                                        { user?.Attributes?.given_name } { user?.Attributes?.family_name }, { user?.Attributes?.email }
                                        { user?.Attributes?.profile ? <span style={{ marginLeft: 10 }}><UniversalTag category={user?.Attributes?.profile} /></span> : <></> }
                                      </li>
                                    })
                                  }
                                </ul>
                              </TableCell>
                            </TableRow>
                          }
                          {
                            showServiceLimits &&
                            customer?.serviceLimits?.map((serviceLimit: any) => {
                              return <TableRow>
                                <TableCell>{serviceLimit.name}</TableCell>
                                <TableCell>{serviceLimit.limitApplied}</TableCell>
                                <TableCell style={{ color: serviceLimit.source === 'OVERRIDE' ? 'var(--amplify-colors-orange-60)' : '' }} colSpan={2}>{serviceLimit.source}</TableCell>
                              </TableRow>
                            })
                          }
                        </>
                      })
                    }
                  </Table>
                  <Flex justifyContent="space-between" alignItems="center" style={{ marginTop: 20 }}>
                    <h2>All Signed Up Users ({ response?.signedUpUsers?.length })</h2>
                    <div>
                      <ToggleButton
                        size="small"
                        isPressed={showUsage}
                        onChange={() => setShowUsage(!showUsage)}
                        style={{ marginLeft: 10 }}
                      >
                        { showUsage ? 'Hide usage' : 'Show usage' }
                      </ToggleButton>
                    </div>
                  </Flex>
                  <Table size="small" backgroundColor="white">
                    {
                      response?.signedUpUsers?.map((user: any, i: number) => {
                        return <>
                          <TableRow key={i}>
                            <TableCell>#{i + 1}</TableCell>
                            <TableCell>{user.firstName}</TableCell>
                            <TableCell>{user.lastName}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user.createdAt.substring(0, 10)} ({ General.daysAgo(user.createdAt) })</TableCell>
                          </TableRow>
                          {
                            showUsage &&
                            response?.usageMap[user.pk?.split('#')[1]]?.map((usage: any) => {
                              return <TableRow>
                                <TableCell colSpan={3}>{usage.date}</TableCell>
                                <TableCell colSpan={2}><strong>{usage.nrRequests}</strong> API requests</TableCell>
                              </TableRow>
                            })
                          }
                        </>
                      })
                    }
                    
                  </Table>
                </>
          }
        </div>
      </>
    }
  </GlobalContext.Consumer>
}
