import NA from "./NA";
import './EmailAddress.scss';
import { BiCheck, BiCheckDouble, BiQuestionMark, BiX } from "react-icons/bi";
import General from '../utils/GeneralUtils';

export default function EmailAddress({ email = null, emailDomainCount = null, emailVerificationStatus = null, emailLastVerifiedAt = null }) {
  if (typeof email !== 'string') return <NA />
  const segments = (email as string).split('@')
  return <>
    <span style={{ paddingRight: 24 }}>
      {
        email === '[REDACTED]'
          ? <span className="blur-text">redacted@email.com</span>
          : <>
              {
                emailDomainCount && emailDomainCount > 0
                ? <>{ segments[0] }<span
                  className="email-domain"
                  data-tooltip-id="main-tooltip"
                  data-tooltip-content={emailDomainCount + ' emails associated with domain @' + segments[1]}
                  data-tooltip-delay-show={50}
                >@{ segments[1] }</span></>
                : <>{ email }</>
              }
            </>
      }
      {
        emailVerificationStatus === '[REDACTED]'
          ? <></>
          : <span style={{ marginLeft: '5px', position: 'absolute' }}>
              {
                emailVerificationStatus === 'valid'
                  ? <BiCheckDouble
                    fontSize={20}
                    style={{ transform: 'translateY(1px)', color: 'var(--amplify-colors-green-90)' }}
                    data-tooltip-id="main-tooltip"
                    data-tooltip-content={`Valid, last verified on ${General.formatDate(emailLastVerifiedAt)}`}
                    data-tooltip-delay-show={0}
                    />
                  : <></>
              }
              {
                emailVerificationStatus === 'catch-all'
                  ? <BiCheck
                    fontSize={20}
                    style={{ transform: 'translateY(1px)', color: 'var(--amplify-colors-green-90)' }}
                    data-tooltip-id="main-tooltip"
                    data-tooltip-content={`Catch-all, last verified on ${General.formatDate(emailLastVerifiedAt)}`}
                    data-tooltip-delay-show={0}
                    />
                  : <></>
              }
              {
                emailVerificationStatus === 'invalid'
                  ? <BiX
                    fontSize={20}
                    style={{ transform: 'translateY(1px)', color: 'var(--amplify-colors-red-90)' }}
                    data-tooltip-id="main-tooltip"
                    data-tooltip-content={`Invalid, last verified on ${General.formatDate(emailLastVerifiedAt)}`}
                    data-tooltip-delay-show={0}
                    />
                  : <></>
              }
              {
                emailVerificationStatus === 'abuse' || emailVerificationStatus === 'do_not_mail'
                  ? <BiQuestionMark
                    fontSize={20}
                    style={{ transform: 'translateY(1px)', color: 'var(--amplify-colors-neutral-60)' }}
                    data-tooltip-id="main-tooltip"
                    data-tooltip-content={`Email status is '${emailVerificationStatus}', last verified on ${General.formatDate(emailLastVerifiedAt)}`}
                    data-tooltip-delay-show={0}
                    />
                  : <></>
              }
            </span>
      }
    </span>
  </>
}
