import { Link as AmplifyLink } from '@aws-amplify/ui-react';
import { Grid } from "@aws-amplify/ui-react";
import GradeSpan from "./GradeSpan";
import PhysicalAddress from "./PhysicalAddress";
import NA from './NA';
import General from '../utils/GeneralUtils';
import { FaExternalLinkAlt } from 'react-icons/fa';
import Currency from './Currency';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { GlobalContext } from '../global-context';

enum CardType {
  DISTRICT = 'DISTRICT',
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
  HOSPITAL = 'HOSPITAL',
  IDN = 'IDN',
}

function getCardType(institution: any): CardType {
  if (institution.type === 'DISTRICT') {
    return CardType.DISTRICT
  } else if (institution.type === 'SCHOOL') {
    if (institution.access === 'PRIVATE') {
      return CardType.PRIVATE
    }
  } else if (institution.type === 'HOSPITAL') {
    return CardType.HOSPITAL
  } else if (institution.type === 'IDN') {
    return CardType.IDN
  }
  return CardType.PUBLIC
}

const props = {
  [CardType.DISTRICT]: {
    name: 'District',
    classes: 'institution-card-district'
  },
  [CardType.PUBLIC]: {
    name: 'Public School',
    classes: 'institution-card-private-school'
  },
  [CardType.PRIVATE]: {
    name: 'Private School',
    classes: 'institution-card-private-school'
  },
  [CardType.IDN]: {
    name: 'IDN',
    classes: 'institution-card-idn'
  },
  [CardType.HOSPITAL]: {
    name: 'Hospital',
    classes: 'institution-card-hospital'
  }
}

export default function InstitutionTopCard(input: any) {
  const institution = input.institution;

  const globalContext = useContext(GlobalContext);
  const cardType = getCardType(institution);
  const cardTypeProps = props[cardType];
  
  return <div className={`institution-card dark-theme ${ cardTypeProps.classes }`}>
    <Grid
      templateColumns="1fr 1fr"
    >
      <div className='side'>
        <div className='row tall'>
          <strong>{ cardTypeProps.name }</strong>{ institution.type2 && institution.type2 !== 'NONE' ? <span>, {General.getHumanReadableType2(institution.type2)}</span> : '' }
        </div>
        {
          ([CardType.PRIVATE, CardType.PUBLIC].includes(cardType)) && <div className='row'><GradeSpan row={institution} /></div>
        }
        {
          cardType === CardType.PRIVATE &&
            <>
              <div className='row'>Religious Affiliation: { institution.religiousAffiliation ? General.getHumanReadableReligiousAffiliation(institution.religiousAffiliation) : 'None' }</div>
              <div className='row'>Annual Tuition: { <Currency amount={institution.tuitionAnnual} /> || <NA /> }</div>
              { institution?.stfTotal > 0 && <div className='row'>Nr. Staff: { institution.stfTotal || <NA /> }</div> }
              { institution?.nrStudents > 10 && <div className='row'>Nr. Students: { institution.nrStudents || <NA /> }</div> }
            </>
        }
        {
          cardType === CardType.PUBLIC &&
            <>
              <div className='row'>Charter School: { institution.isCharter ? 'Yes' : 'No' }</div>
            </>
        }
        {
          cardType === CardType.DISTRICT &&
            <>
              <div className='row'><span>Type</span> { General.getDistrictTypeObject(institution['charterLea']) }</div>
              <div className='row'><span>Annual Revenue</span> <Currency amount={institution['fsclRevTotal']} /></div>
              <div className='row'><span>Nr. Schools</span> { General.roundString(institution['totalSchools']) }</div>
              <div className='row'><span>Nr. Staff</span> { General.roundString(institution['stfTotal']) }</div>
              <div className='row'><span>Nr. Students</span> { General.roundString(institution['studentsTotal']) }</div>
              <div className='row'><span>Student/Teacher Ratio</span> { General.roundString(institution['studentTchrRatio']) }</div>
            </>
        }
      </div>
      <div className='side'>
        {
          ([CardType.PRIVATE, CardType.PUBLIC, CardType.HOSPITAL, CardType.DISTRICT].includes(cardType)) &&
            <>
              <div className='row tall'><strong>{ General.getStateName(institution.locState) }</strong></div>
              <div className='row'><PhysicalAddress row={institution} isLink={true} /></div>
              {/* <div className='row'>{ institution['website'] ? <AmplifyLink target='_blank' href={ institution['website'] }>{ General.ellipsisInMiddle(institution['website'], 56) } <FaExternalLinkAlt size={11} /></AmplifyLink> : <NA />}</div> */}
            </>
        }
        {
          institution?.associatedUrls?.length
            ? institution?.associatedUrls?.filter((associatedUrl: any) => associatedUrl?.pageType === 'WEBSITE')?.map((associatedUrl: any) => {
                return <div className="row"><AmplifyLink target='_blank' href={ associatedUrl?.url }>{ General.ellipsisInMiddle(associatedUrl?.url, 56) } <FaExternalLinkAlt size={11} /></AmplifyLink></div>
              })
            : <></>
        }
        { institution.phone && <div className='row'><span className='narrow'>Phone</span> { institution.phone }</div> }
        { institution.fax && <div className='row'><span className='narrow'>Fax</span> { institution.fax }</div> }
        { institution.pattern && <div className='row'><span className='narrow'>Email Format</span> { institution.pattern }</div> }
        {
          institution?.domains?.length &&
            <div className='row'>
              <span className='narrow'>Domains</span> {
                institution.domains.map((domain: any) => {
                  return <>
                    <Link className='link' to={`/directory/${globalContext.environment.entities.domains.urlSegment}/${domain.domain}`}>{ domain.domain }</Link>
                  </>
                })
              }
            </div>
        }
        {
          (
            institution['linkedin'] ||
            institution['twitter'] ||
            institution['facebook'] ||
            institution['instagram'] ||
            institution['youtube']
          ) &&
          <div className='row'>
            { institution['linkedin'] && <AmplifyLink target='_blank' style={{ marginRight: 10 }} href={ institution['linkedin'] }>LinkedIn <FaExternalLinkAlt size={11} /></AmplifyLink> }
            { institution['twitter'] && <AmplifyLink target='_blank' style={{ marginRight: 10 }} href={ institution['twitter'] }>Twitter <FaExternalLinkAlt size={11} /></AmplifyLink> }
            { institution['facebook'] && <AmplifyLink target='_blank' style={{ marginRight: 10 }} href={ institution['facebook'] }>Facebook <FaExternalLinkAlt size={11} /></AmplifyLink> }
            { institution['instagram'] && <AmplifyLink target='_blank' style={{ marginRight: 10 }} href={ institution['instagram'] }>Instagram <FaExternalLinkAlt size={11} /></AmplifyLink> }
            { institution['youtube'] && <AmplifyLink target='_blank' style={{ marginRight: 10 }} href={ institution['youtube'] }>YouTube <FaExternalLinkAlt size={11} /></AmplifyLink> }
          </div>
        }
      </div>
    </Grid>
  </div>
}
