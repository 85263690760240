import { post } from 'aws-amplify/api';
import General from './GeneralUtils';
import { fetchAuthSession, fetchUserAttributes, getCurrentUser } from 'aws-amplify/auth';
import { AuthInfo } from '../App';

export class TeamDao {

  static async request(body: any): Promise<any> {
    const headers = await General.getAuthorizationHeaders()
    const operation = post({
      apiName: 'apiMain',
      path: '/data',
      options: { headers, body: body || {} }
    });
    const res = await operation.response
    return res.body.json()
  }

  static async listPersonalInvitations() {
    return this.request({
      action: 'LIST_PERSONAL_INVITATIONS',
    })
  }

  static async createOverride(data: any) {
    return this.request({
      action: 'CREATE_OVERRIDE',
      data: data
    })
  }

  static async createInvitation(data: any) {
    return this.request({
      action: 'CREATE_INVITATION',
      data: data
    })
  }

  static async listEvents(data: any) {
    return this.request({
      action: 'LIST_EVENTS',
      data: data
    })
  }

  static async tag(data: any) {
    return this.request({
      action: 'TAG',
      data: data
    })
  }

  static async untag(data: any) {
    return this.request({
      action: 'UNTAG',
      data: data
    })
  }

  static async createProspectorJob(data: any) {
    return this.request({
      action: 'CREATE_PROSPECTOR_JOB',
      data: data
    })
  }

  static async getProspectorJob(data: any) {
    return this.request({
      action: 'GET_PROSPECTOR_JOB',
      data: data
    })
  }

  static async getSettingsPage() {
    return this.request({
      action: 'GET_SETTINGS_PAGE',
      data: {}
    })
  }

  static async getTeamMembers() {
    return this.request({
      action: 'GET_TEAM_MEMBERS',
      data: {}
    })
  }

  static async listInvoices() {
    return this.request({
      action: 'LIST_INVOICES',
      data: {}
    })
  }

  static async downloadInvoice(data: any) {
    return this.request({
      action: 'DOWNLOAD_INVOICE',
      data: data
    })
  }

  static async listQueries(data: any) {
    return this.request({
      action: 'LIST_QUERIES',
      data: data
    })
  }

  static async deleteQuery(data: any) {
    return this.request({
      action: 'DELETE_QUERY',
      data: data
    })
  }

  static async listTags() {
    return this.request({
      action: 'LIST_TAGS',
      data: {}
    })
  }

  static async createQuery(data: any) {
    return this.request({
      action: 'CREATE_QUERY',
      data: data
    })
  }

  static async updateInvitation(data: any) {
    return this.request({
      action: 'UPDATE_INVITATION',
      data: data
    })
  }

  static async dbSearch(data: any) {
    return this.request({
      action: 'DB_SEARCH',
      data: data
    })
  }

  static async dbGet(data: any) {
    return this.request({
      action: 'DB_GET',
      data: data
    })
  }

  static async getTeam() {
    return this.request({
      action: 'GET_TEAM',
    })
  }

  static async getTeamPage() {
    return this.request({
      action: 'GET_TEAM_PAGE',
      data: {}
    })
  }

  static async getIDash() {
    return this.request({
      action: 'GET_IDASH',
      data: {}
    })
  }

  static async fetchAuthInfo(): Promise<AuthInfo> {
    const currentSession = await fetchAuthSession({ forceRefresh: true });
    const currentAuthenticatedUser = await getCurrentUser();
    const userAttributes = await fetchUserAttributes();
    const accessToken = await currentSession.tokens?.accessToken
    const team = await TeamDao.getTeam()
    const cognitoGroups = accessToken?.payload['cognito:groups'];
  
    const userId = currentSession.userSub as string
    const teamId = cognitoGroups?.toString() as string
  
    return {
      teamId,
      userId,
      currentSession,
      currentAuthenticatedUser,
      userAttributes,
      team: team?.data || null
    }
  }

}