import QueryBuilder, { ActionElement, Controls, FieldSelectorProps, OptionList, RuleGroupType, RuleType, ValidationResult, ValueEditor, ValueEditorProps } from 'react-querybuilder';
import './QueryEditor.scss';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { MemoShapeOnMap } from './ShapeOnMap';
import General from '../utils/GeneralUtils';
import { IconsProvider, Menu, TextAreaField } from '@aws-amplify/ui-react';
import { BiChevronDown } from 'react-icons/bi';

const operators: any = {
  '=': 'equals',
  '!=': 'does not equal',
  '<': 'less than',
  '>': 'greater than',
  'contains': 'contains',
  'containsWord': 'contains word',
  'beginsWith': 'begins with',
  'endsWith': 'ends with',
  'doesNotContain': 'does not contain',
  'doesNotBeginWith': 'does not begin with',
  'doesNotEndWith': 'does not end with',
  'notNull': 'is set',
  'isNull': 'is not set',
  'in': 'one of',
  'notIn': 'not one of',
  'between': 'between',
  'notBetween': 'not between'
}

const type2Values = [
  {
    "name": "MONTESSORI",
    "label": "Montessori"
  },
  {
    "name": "SPECIAL_EDUCATION",
    "label": "Special Education"
  },
  {
    "name": "EARLY_CHILDHOOD_DAY_CARE",
    "label": "Early Childhood Day Care"
  },
  {
    "name": "ALTERNATIVE",
    "label": "Alternative"
  },
  {
    "name": "SPECIAL_PROGRAM_EMPHASIS",
    "label": "Special Program Emphasis"
  },
  {
    "name": "VOCATIONAL_TECHNICAL",
    "label": "Vocational Technical"
  },
  {
    "name": "WALDORF",
    "label": "Waldorf"
  },
  {
    "name": "ONLINE",
    "label": "Online"
  },
  {
    "name": "MILITARY",
    "label": "Military"
  }
]

const religiousAffiliationValues = [
  {
    "name": "AFRICAN_METHODIST_EPISCOPAL",
    "label": "African Methodist Episcopal"
  },
  {
    "name": "AMISH",
    "label": "Amish"
  },
  {
    "name": "ANGLICAN",
    "label": "Anglican"
  },
  {
    "name": "ASSEMBLY_OF_GOD",
    "label": "Assembly of God"
  },
  {
    "name": "BAPTIST",
    "label": "Baptist"
  },
  {
    "name": "BRETHREN",
    "label": "Brethren"
  },
  {
    "name": "CALVINIST",
    "label": "Calvinist"
  },
  {
    "name": "CATHOLIC",
    "label": "Catholic"
  },
  {
    "name": "CHRISTIAN",
    "label": "Christian"
  },
  {
    "name": "CHURCH_OF_CHRIST",
    "label": "Church of Christ"
  },
  {
    "name": "CHURCH_OF_GOD",
    "label": "Church of God"
  },
  {
    "name": "CHURCH_OF_GOD_IN_CHRIST",
    "label": "Church of God in Christ"
  },
  {
    "name": "CHURCH_OF_THE_NAZARENE",
    "label": "Church of the Nazarene"
  },
  {
    "name": "DISCIPLES_OF_CHRIST",
    "label": "Disciples of Christ"
  },
  {
    "name": "EPISCOPAL",
    "label": "Episcopal"
  },
  {
    "name": "EVANGELICAL_LUTHERAN_CHURCH_IN_AMERICA",
    "label": "Evangelical Lutheran Church in America"
  },
  {
    "name": "FRIENDS",
    "label": "Friends"
  },
  {
    "name": "GREEK_ORTHODOX",
    "label": "Greek Orthodox"
  },
  {
    "name": "ISLAMIC",
    "label": "Islamic"
  },
  {
    "name": "JEWISH",
    "label": "Jewish"
  },
  {
    "name": "LATTER_DAY_SAINTS",
    "label": "Latter Day Saints"
  },
  {
    "name": "LUTHERAN_CHURCH_MISSOURI_SYNOD",
    "label": "Lutheran Church Missouri Synod"
  },
  {
    "name": "MENNONITE",
    "label": "Mennonite"
  },
  {
    "name": "METHODIST",
    "label": "Methodist"
  },
  {
    "name": "NONSECTARIAN",
    "label": "Nonsectarian"
  },
  {
    "name": "OTHER",
    "label": "Other"
  },
  {
    "name": "OTHER_LUTHERAN",
    "label": "Other Lutheran"
  },
  {
    "name": "PENTECOSTAL",
    "label": "Pentecostal"
  },
  {
    "name": "PRESBYTERIAN",
    "label": "Presbyterian"
  },
  {
    "name": "SEVENTH_DAY_ADVENTIST",
    "label": "Seventh Day Adventist"
  },
  {
    "name": "WISCONSIN_EVANGELICAL_LUTHERAN_SYNOD",
    "label": "Wisconsin Evangelical Lutheran Synod"
  }
]

const idToNameFieldMap: any = {
  title: 'Title',
  titleCategory: 'Title Category',
  name: 'Name',
  email: 'Email',
  emailVerificationStatus: 'Email Verification',
  phone: 'Phone',
  fax: 'Fax',
  shapes: 'Map',
  state: 'State',
  zip: 'ZIP Code',
  districtRevTotal: 'District Revenue',
  stfTotal: 'Total Staff',
  parentName: 'District Name',
  studentTchrRatio: 'Student/Teacher Ratio',
  studentsTotal: 'Nr. of Students',
  totalSchools: 'Nr. of Schools',
  countyId: 'County',
  institutionType: 'Type',
  religiousAffiliation: 'Religious Affiliation',
  fulltext: 'Website Text',
  grade: 'Grades',
  tag: 'Tags',
  ncesId: 'NCES ID',
  belongsToCountTotal: 'Nr. of Institutions',
  institutionId: 'Institution ID',
}

const combinators = [
  { name: 'and', label: 'And' },
  { name: 'or', label: 'Or' },
]

function getOperators(selectedOperators: string[]) {
  return selectedOperators.map(it => ({ name: it, label: operators[it] })).filter(it => !!it)
}

function getAllOperators() {
  return getOperators(Object.keys(operators))
}

function parseQuery(query?: string) {
  try {
    if (!query) {
      console.warn(`query is '${query}'`)
      return null
    }
    return JSON.parse(atob(query))
  } catch (err) {
    console.error('exception parsing query')
    return null
  }
}

function serializeQuery(query: RuleGroupType) {
  try {
    if (!query) return null
    const clone = JSON.parse(JSON.stringify(query))
    delete clone.id
    if (Array.isArray(clone.rules)) {
      for (const rule of clone.rules) {
        delete rule.id
        if (Array.isArray(rule.rules)) {
          for (const subrule of rule.rules) {
            delete subrule.id
          }
        }
      }
    }
    return btoa(JSON.stringify(clone))
  } catch {
    return null
  }
}

const CustomSourceSelector = (props: FieldSelectorProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenChange = (isOpen: boolean) => { setIsOpen(isOpen) };

  function selectField(props: FieldSelectorProps, field: string) {
    props.handleOnChange(field);
    setIsOpen(false);
  }

  const fieldSet: any = {}
  for (const item of props.options as any) fieldSet[item.name] = item.label

  return (
    <div style={{ position: 'relative' }}>

      <IconsProvider
        icons={{
          menu: {
            menu: <><span >{ idToNameFieldMap[props.rule.field as any] }</span> <BiChevronDown style={{ marginLeft: 5 }} /></>,
          },
        }}
      >
        <Menu
          className='mymenu'
          isOpen={isOpen}
          onOpenChange={handleOpenChange}
        >
          <div className='query-selector-panel'>
            <div className="outter">
              <div className="inner">
                <div style={{ gridColumn: 1 }}>
                  <div className="header desc-7" style={{ marginBottom: 10 }}>Contact</div>
                  { fieldSet['name'] && <div className="row" onClick={() => selectField(props, 'name')}>{ fieldSet['name'] }</div> }
                  { fieldSet['title'] && <div className="row" onClick={() => selectField(props, 'title')}>{ fieldSet['title'] }</div> }
                  { fieldSet['email'] && <div className="row" onClick={() => selectField(props, 'email')}>{ fieldSet['email'] }</div> }
                  { fieldSet['phone'] && <div className="row" onClick={() => selectField(props, 'phone')}>{ fieldSet['phone'] }</div> }
                  { fieldSet['titleCategory'] && <div className="row" onClick={() => selectField(props, 'titleCategory')}>{ fieldSet['titleCategory'] }</div> }
                  { fieldSet['emailVerificationStatus'] && <div className="row" onClick={() => selectField(props, 'emailVerificationStatus')}>{ fieldSet['emailVerificationStatus'] }</div> }
                  { fieldSet['tag'] && <div className="row" onClick={() => selectField(props, 'tag')}>{ fieldSet['tag'] }</div> }
                </div>
                <div style={{ gridColumn: 2 }}>
                  <div className="header desc-7" style={{ marginBottom: 10 }}>Institution</div>
                  { fieldSet['institutionType'] && <div className="row" onClick={() => selectField(props, 'institutionType')}>{ fieldSet['institutionType'] }</div> }
                  { fieldSet['districtRevTotal'] && <div className="row" onClick={() => selectField(props, 'districtRevTotal')}>{ fieldSet['districtRevTotal'] }</div> }
                  { fieldSet['parentName'] && <div className="row" onClick={() => selectField(props, 'parentName')}>{ fieldSet['parentName'] }</div> }
                  { fieldSet['stfTotal'] && <div className="row" onClick={() => selectField(props, 'stfTotal')}>{ fieldSet['stfTotal'] }</div> }
                  { fieldSet['studentsTotal'] && <div className="row" onClick={() => selectField(props, 'studentsTotal')}>{ fieldSet['studentsTotal'] }</div> }
                  { fieldSet['studentTchrRatio'] && <div className="row" onClick={() => selectField(props, 'studentTchrRatio')}>{ fieldSet['studentTchrRatio'] }</div> }
                  { fieldSet['fulltext'] && <div className="row" onClick={() => selectField(props, 'fulltext')}>{ fieldSet['fulltext'] }</div> }
                  { fieldSet['grade'] && <div className="row" onClick={() => selectField(props, 'grade')}>{ fieldSet['grade'] }</div> }
                  { fieldSet['religiousAffiliation'] && <div className="row" onClick={() => selectField(props, 'religiousAffiliation')}>{ fieldSet['religiousAffiliation'] }</div> }
                  { fieldSet['ncesId'] && <div className="row" onClick={() => selectField(props, 'ncesId')}>{ fieldSet['ncesId'] }</div> }
                  { fieldSet['belongsToCountTotal'] && <div className="row" onClick={() => selectField(props, 'belongsToCountTotal')}>{ fieldSet['belongsToCountTotal'] }</div> }
                  { fieldSet['institutionId'] && <div className="row" onClick={() => selectField(props, 'institutionId')}>{ fieldSet['institutionId'] }</div> }
                </div>
                <div style={{ gridColumn: 3 }}>
                  <div className="header desc-7" style={{ marginBottom: 10 }}>Location</div>
                  { fieldSet['state'] && <div className="row" onClick={() => selectField(props, 'state')}>{ fieldSet['state'] }</div> }
                  { fieldSet['zip'] && <div className="row" onClick={() => selectField(props, 'zip')}>{ fieldSet['zip'] }</div> }
                  { fieldSet['countyId'] && <div className="row" onClick={() => selectField(props, 'countyId')}>{ fieldSet['countyId'] }</div> }
                  { fieldSet['shapes'] && <div className="row" onClick={() => selectField(props, 'shapes')}>{ fieldSet['shapes'] }</div> }
                </div>
              </div>
            </div>
          </div>
        </Menu>
      </IconsProvider>
    </div>
  )
}

const CustomValueEditor = (props: ValueEditorProps) => {
  if (props.fieldData.datatype === 'map') {
    let searchParams;
    try {
      searchParams = {
        shapes: {
          json: JSON.parse(atob(props.value))
        }
      }
    } catch {}
    return (
      <div style={{ width: 890, height: 440, position: 'relative' }}>
        <MemoShapeOnMap
          searchParams={searchParams}
          onChange={(updatedShapes) => props.handleOnChange(updatedShapes)}
        />
      </div>
    )
  }
  if (props.fieldData.datatype === 'textarea') {
    return (
      <div>
        {/* <div className='desc-5' style={{ position: 'absolute', top: -14 }}>{ 1000 - props.value?.length } characters remaining</div> */}
        <TextAreaField
          label=''
          rows={1}
          width={522}
          maxLength={10000}
          value={props.value}
          resize='vertical'
          placeholder={props?.fieldData?.placeholder || ''}
          onChange={(event: any) => props.handleOnChange(event.target.value)}
        />
      </div>
    )
  }
  if (props.fieldData.datatype === 'creatable') {
    return (
      <div style={{ width: 522 }}>
        <Select
          value={
            props.value?.split(',')
              .map((it: string) => it.trim())
              .filter((it: string) => !!it)
              .map((it: string) => ({
                value: it,
                label: typeof props.fieldData?.options?.getValue === 'function' ? props.fieldData.options.getValue(it) : it
              }))
          }
          onChange={(event: any) => { props.handleOnChange(event.map((it: any) => it.value).join(',')) }}
          isMulti={true}
          styles={General.getCreatableStylesCompact()}
          options={props.values?.map(it => ({ value: it.name, label: it.label }))}
          isClearable={false}
        />
      </div>
    )
  }
  return (
    <div style={{ position: 'relative' }}>
      <ValueEditor {...props} />
      {/* {
        (props?.validation as any)?.valid === false ?
          <span className='desc-5' style={{ position: 'absolute', bottom: -16, right: 0 }}>{ (props?.validation as any).reasons[0] }</span> :
          ''
      } */}
    </div>
  );
};

export default function QueryEditor({
  initialQuery,
  supportedFields,
  teamTags,
  type = 'standard',
  onChange
}: {
  initialQuery?: string,
  supportedFields: string[],
  teamTags: any[],
  type: 'standard' | 'contacts',
  onChange: (query: string | null) => void
}) {

  const [query, setQuery] = useState(parseQuery(initialQuery));

  useEffect(() => {
    setQuery(parseQuery(initialQuery))
  }, [initialQuery])

  function onQueryChange(query: any) {
    setQuery(query)
    onChange(serializeQuery(query))
  }

  function minLengthValidator(ln = 1, rule: RuleType): ValidationResult {
    return {
      valid: rule?.value?.length >= ln,
      reasons: [`Type at least ${ln} character${ln === 1 ? '' : 's'}`]
    }
  }

  function numericValidator(rule: RuleType): ValidationResult {
    return {
      valid: !isNaN(rule?.value),
      reasons: [`Must be a number`]
    }
  }

  function getFields(): OptionList {
    const fields: OptionList = [
      {
        name: 'title',
        label: 'Title',
        defaultOperator: 'contains',
        operators: getOperators(['=', 'contains', 'doesNotContain', 'containsWord', 'beginsWith', 'endsWith', 'notNull']),
        validator: minLengthValidator.bind(null, 1)
      },
      {
        name: 'titleCategory',
        label: 'Title Category',
        valueEditorType: 'select',
        defaultOperator: '=',
        defaultValue: 'MANAGER',
        values: [{ label: 'Manager', name: 'MANAGER' }, { label: 'Teacher', name: 'TEACHER' }, { label: 'Other Staff', name: 'OTHER' }],
        operators: getOperators(['=', '!='])
      },
      {
        name: 'name',
        label: 'Name',
        defaultOperator: 'contains',
        operators: getOperators(['=', 'contains', 'notNull']),
        validator: minLengthValidator.bind(null, 1)
      },
      {
        name: 'id',
        label: 'Domain',
        defaultOperator: 'contains',
        operators: getOperators(['=', 'contains', 'notNull']),
        validator: minLengthValidator.bind(null, 1)
      },
      {
        name: 'email',
        label: 'Email',
        defaultOperator: 'notNull',
        operators: getOperators(['=', 'contains', 'endsWith', 'notNull', '!=', 'doesNotContain', 'doesNotEndWith']),
        validator: minLengthValidator.bind(null, 1)
      },
      {
        name: 'emailVerificationStatus',
        label: 'Email Verification',
        valueEditorType: 'select',
        defaultOperator: '!=',
        defaultValue: 'invalid',
        values: [{ label: 'Valid', name: 'valid' }, { label: 'Valid - Catch All', name: 'catch-all' }, { label: 'Invalid', name: 'invalid' }],
        operators: getOperators(['=', '!='])
      },
      {
        name: 'phone',
        label: 'Phone',
        defaultOperator: 'notNull',
        operators: getOperators(['=', 'contains', 'notNull', '!=', 'doesNotContain']),
        validator: minLengthValidator.bind(null, 1)
      },
      {
        name: 'fax',
        label: 'Fax',
        defaultOperator: 'notNull',
        operators: getOperators(['=', 'contains', 'notNull', '!=', 'doesNotContain']),
        validator: minLengthValidator.bind(null, 1)
      },
      {
        name: 'shapes',
        label: 'Map',
        datatype: 'map',
        operators: getAllOperators(),
        className: 'map'
      },
      {
        name: 'state',
        label: 'State',
        datatype: 'creatable',
        defaultValue: '',
        values: General.getStatesForSelect().map(it => ({ name: it.value, label: it.label })),
        operators: getOperators(['=', '!=']),
        options: {
          getValue: General.getStateName
        }
      },
      {
        name: 'zip',
        label: 'ZIP code',
        defaultOperator: '=',
        operators: getOperators(['=', 'beginsWith', 'endsWith', '!=', 'doesNotBeginWith', 'doesNotEndWith']),
        validator: minLengthValidator.bind(null, 1)
      },
      {
        name: 'districtRevTotal',
        label: 'District annual revenue',
        defaultOperator: '>',
        operators: getOperators(['>', '<', 'between']),
        validator: numericValidator.bind(null)
      },
      {
        name: 'parentName',
        label: 'District name',
        datatype: 'textarea',
        placeholder: 'Comma separated list of district names (max 10000 characters)',
        defaultOperator: 'contains',
        operators: getOperators(['contains', '=']),
        validator: numericValidator.bind(null)
      },
      {
        name: 'stfTotal',
        label: 'Total staff',
        defaultOperator: '>',
        operators: getOperators(['>', '<', 'between']),
        validator: numericValidator.bind(null)
      },
      {
        name: 'studentTchrRatio',
        label: 'Student/Teacher ratio',
        defaultOperator: '>',
        operators: getOperators(['>', '<', 'between']),
        validator: numericValidator.bind(null)
      },
      {
        name: 'studentsTotal',
        label: 'Total students',
        defaultOperator: '>',
        operators: getOperators(['>', '<', 'between']),
        validator: numericValidator.bind(null)
      },
      {
        name: 'totalSchools',
        label: 'Nr. of schools',
        defaultOperator: '>',
        operators: getOperators(['>', '<', 'between']),
        validator: numericValidator.bind(null)
      },
      {
        name: 'countyId',
        label: 'County',
        datatype: 'creatable',
        defaultValue: '',
        values: General.getCountiesForSelect(),
        operators: getOperators(['=', '!=']),
        validator: minLengthValidator.bind(null, 1),
        options: {
          getValue: General.getCountiesObject
        }
      },
      {
        name: 'institutionType',
        label: 'Institution Type',
        valueEditorType: 'select',
        defaultOperator: '=',
        defaultValue: 'public',
        values: [{ label: 'Public School', name: 'public' }, { label: 'Private School', name: 'private' }, { label: 'Charter School', name: 'charter' }],
        operators: getOperators(['='])
      },
      {
        name: 'religiousAffiliation',
        label: 'Religious Affiliation',
        datatype: 'creatable',
        defaultValue: '',
        values: religiousAffiliationValues,
        operators: getOperators(['=']),
        options: {
          getValue: General.getHumanReadableReligiousAffiliation
        }
      },
      {
        name: 'schoolReligiousAffiliation',
        label: 'Religious Affiliation',
        datatype: 'creatable',
        defaultValue: '',
        values: religiousAffiliationValues,
        operators: getOperators(['=']),
        options: {
          getValue: General.getHumanReadableReligiousAffiliation
        }
      },
      {
        name: 'schoolAccess',
        label: 'Access',
        valueEditorType: 'select',
        defaultOperator: '=',
        defaultValue: 'public',
        values: [{ label: 'Public', name: 'PUBLIC' }, { label: 'Private', name: 'PRIVATE' }],
        operators: getOperators(['='])
      },
      {
        name: 'schoolType',
        label: 'Type',
        datatype: 'creatable',
        defaultValue: '',
        values: type2Values,
        operators: getOperators(['=']),
        options: {
          getValue: General.getHumanReadableType2
        }
      },
      {
        name: 'fulltext',
        label: 'Website text',
        operators: getOperators(['contains']),
        validator: minLengthValidator.bind(null, 5)
      },
      {
        name: 'grade',
        label: 'Grades',
        datatype: 'creatable',
        defaultValue: '',
        values: General.getGradesForSelect(),
        operators: getOperators(['=', '!=']),
        options: {
          getValue: General.getGradesObject
        }
      },
      {
        name: 'tag',
        label: 'Tag',
        datatype: 'creatable',
        defaultValue: '',
        values: teamTags.map(it => ({ name: it, label: it })),
        operators: getOperators(['='])
      },
      {
        name: 'charterLea',
        label: 'Type',
        datatype: 'creatable',
        defaultValue: '',
        values: General.getDistrictTypeForSelect(),
        operators: getOperators(['=', '!=']),
        options: {
          getValue: General.getDistrictTypeObject
        }
      },
      {
        name: 'ncesId',
        label: 'NCES ID',
        datatype: 'textarea',
        placeholder: 'Comma separated list of NCES IDs (max 10000 characters)',
        operators: getOperators(['=', '!=']),
        validator: minLengthValidator.bind(null, 5)
      },
      {
        name: 'belongsToCountTotal',
        label: 'Nr. of Associated Institutions',
        defaultOperator: '>',
        operators: getOperators(['>', '<', 'between']),
        validator: numericValidator.bind(null)
      },
      {
        name: 'institutionId',
        label: 'Institution ID',
        datatype: 'textarea',
        placeholder: 'Comma separated list of Institution IDs (max 10000 characters)',
        operators: getOperators(['=', '!=']),
        validator: minLengthValidator.bind(null, 5)
      },
      {
        name: 'nrImportantUrls',
        label: 'Nr. of Important URLs',
        defaultOperator: '>',
        operators: getOperators(['=', '>', '<', 'between']),
        validator: numericValidator.bind(null)
      },
      {
        name: 'nrEmailDomains',
        label: 'Nr. of Email Domains',
        defaultOperator: '>',
        operators: getOperators(['=', '>', '<', 'between']),
        validator: numericValidator.bind(null)
      },
      {
        name: 'nrInstitutions',
        label: 'Nr. of Institutions',
        defaultOperator: '>',
        operators: getOperators(['=', '>', '<', 'between']),
        validator: numericValidator.bind(null)
      },
      {
        name: 'nrLinkedDomains',
        label: 'Nr. of Linked Domains',
        defaultOperator: '>',
        operators: getOperators(['=', '>', '<', 'between']),
        validator: numericValidator.bind(null)
      }
    ];

    return fields.filter(field => supportedFields.includes(field.name));
  }

  function getControlElements(): Partial<Controls> {
    const controlElements: Partial<Controls> = {
      addGroupAction: props => (props.level === 0 ? <ActionElement {...props} label='Add Group' /> : null),
      addRuleAction: props => <ActionElement {...props} label='Add Rule' />,
      removeRuleAction: props => <ActionElement {...props} label='✗' />,
      removeGroupAction: props => <ActionElement {...props} label='✗' />,
      cloneRuleAction: props => <ActionElement {...props} />,
      valueEditor: CustomValueEditor,
    }

    if (type === 'contacts') {
      controlElements.fieldSelector = CustomSourceSelector
    }

    return controlElements;
  }

  return (
    <div>
      <QueryBuilder
        fields={getFields()}
        showCloneButtons={true}
        query={query}
        onQueryChange={onQueryChange}
        combinators={combinators}
        controlClassnames={{
          queryBuilder: 'queryBuilder-branches',
        }}
        controlElements={getControlElements()}
      />
    </div>
  )
}
